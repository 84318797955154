define('scm/pods/logout/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    session: service(),

    beforeModel: function beforeModel(transition) {
      this.get('session').invalidate();
      transition.abort();
    }
  });
});