define('scm/pods/application/touch-zone/component', ['exports', 'ember-gestures/mixins/recognizers'], function (exports, _recognizers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_recognizers.default, {
    recognizers: 'pan',

    panRight: function panRight(e) {
      var x = e.originalEvent.gesture.srcEvent.pageX;
      if (x < 100) {
        this.get('onSidebarOpen')();
      }
    }
  });
});