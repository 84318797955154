define('scm/pods/current-user/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.extend({
    id: null,
    login: null,
    name: null,
    email: null,
    roles: null,
    organization: null,
    isVendor: null
  });
});