define('scm/pods/delivery/new/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('assignment', params.assignment_id).then(function (assignment) {
        return assignment.get('works').then(function (works) {
          var dates = works.map(function (work) {
            return (0, _moment.default)(work.get('completedOn'));
          });
          return _this.store.findAll('delivery').then(function (deliveries) {
            var lastDelivery = deliveries.toArray().sort(function (a, b) {
              return Number(a.get('id')) - Number(b.get('id'));
            }).get('lastObject');
            var lastNumber = lastDelivery ? lastDelivery.get('number') + '+1' : '';
            return _this.store.createRecord('delivery', {
              assignment: assignment,
              number: lastNumber,
              periodFrom: _moment.default.min(dates).toDate() || new Date(),
              periodTo: _moment.default.max(dates).toDate() || new Date(),
              vatRate: 21
            });
          });
        });
      });
    },


    actions: {
      createDelivery: function createDelivery(delivery) {
        var _this2 = this;

        delivery.save().then(function () {
          _this2.get('toaster').show('Dod\xE1vka \u010D. \'' + delivery.get('number') + '\' byla odesl\xE1na.');
          _this2.transitionTo('assignment.show', delivery.get('assignment.id'));
        }).catch(function (res) {
          res.errors.forEach(function (error) {
            _this2.get('toaster').show('Dod\xE1vku \u010D. \'' + delivery.get('number') + '\' se nepoda\u0159ilo odeslat. (' + error.title + ': ' + error.detail + ')');
          });
          delivery.unloadRecord();
        });
      },
      cancelDeliveryCreation: function cancelDeliveryCreation(delivery) {
        var assignmentId = delivery.get('assignment.id');
        delivery.destroyRecord();
        this.transitionTo('assignment.show', assignmentId);
      }
    }
  });
});