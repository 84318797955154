define('scm/pods/sandbox/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findRecord('assignment', '1').then(function (a) {
        return a.get('works');
      });
    },

    actions: {
      showToast: function showToast() {
        console.log('showing toast');
        this.get('toaster').show('Polo\u017Eky byly p\u0159id\xE1ny (po\u010Det: ' + this.length + ').');
      }
    }
  });
});