define('scm/pods/catalog/catalog-form/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var Component = Ember.Component;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    keyboardActivated: true,
    actions: {
      submit: function submit() {
        this.get('onSubmit').apply(undefined, arguments);
      },
      cancel: function cancel() {
        this.get('onCancel').apply(undefined, arguments);
      },
      formSubmit: function formSubmit() {
        this.$().find('form').submit();
      }
    },
    onCtrlEnterPressed: on((0, _emberKeyboard.keyDown)('ctrl+Enter'), function () {
      this.send('formSubmit');
    }),
    onEscPressed: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.send('cancel');
    })
  });
});