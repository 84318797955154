enifed('ember-glimmer/helpers/-normalize-class', ['exports', 'ember-runtime', 'ember-glimmer/utils/references'], function (exports, _emberRuntime, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return new _references.InternalHelperReference(normalizeClass, args.capture());
    };

    function normalizeClass(_ref) {
        var positional = _ref.positional;

        var classNameParts = positional.at(0).value().split('.');
        var className = classNameParts[classNameParts.length - 1];
        var value = positional.at(1).value();
        if (value === true) {
            return _emberRuntime.String.dasherize(className);
        } else if (!value && value !== 0) {
            return '';
        } else {
            return String(value);
        }
    }
});