define('scm/pods/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    menuItems: [{
      link: 'index',
      icon: 'home',
      label: 'Domů',
      hasDividerBefore: false
    }, {
      link: 'delivery',
      icon: 'description',
      label: 'Dodávky',
      hasDividerBefore: true
    }, {
      link: 'assignment',
      icon: 'work',
      label: 'Úkoly',
      hasDividerBefore: false
    }, {
      link: 'statistic',
      icon: 'insert_chart_outlined',
      label: 'Statistika',
      hasDividerBefore: false
    }, {
      link: 'budget',
      icon: 'show_chart',
      label: 'Rozpočet',
      hasDividerBefore: false
    }, {
      link: 'catalog',
      icon: 'money',
      label: 'Cenníky',
      hasDividerBefore: false
    }, {
      link: 'logout',
      icon: 'exit-to-app',
      label: 'Odhlášení',
      hasDividerBefore: true
    }]
  });
});