define('scm/helpers/amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.round = round;
  exports.amount = amount;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  var htmlSafe = Ember.String.htmlSafe;
  function round(value) {
    var dec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    return Number(Math.round(value + 'e' + dec) + 'e-' + dec);
  }

  function amount(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        value = _ref3[0];

    var _ref2$dec = _ref2.dec,
        dec = _ref2$dec === undefined ? 2 : _ref2$dec;

    if (typeof value === 'number') {
      return htmlSafe(round(value, dec).toFixed(dec).replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;'));
    }
    return value;
  }

  exports.default = helper(amount);
});