define('scm/pods/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.changedAttributes()[key] || snapshot.record.get('isNew')) {
        this._super.apply(this, arguments);
      }
    }
  });
});