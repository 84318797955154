define('scm/pods/catalog/select-services/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var empty = Ember.computed.empty;
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var A = Ember.A;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Component.extend({
    store: service('store'),

    tagName: 'span',
    parents: null,
    hasNoPath: empty('parents'),
    items: null,
    initialSelection: null,
    selected: null,
    selectedCount: computed('selected.[]', function () {
      return this.get('selected.length');
    }),
    selectedSize: computed('selected.[]', function () {
      return this.get('selected').reduce(function (sum, item) {
        return sum + item.get('size');
      }, 0);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('parents', A());
      new RSVP.Promise(function (resolve, reject) {
        var rootItems = _this.get('rootItems');
        if (rootItems) {
          _this.set('items', rootItems);
          resolve(_this.get('items'));
        } else {
          _this.get('store').findAll('catalog').then(function (catalogs) {
            _this.set('rootItems', catalogs);
            _this.set('items', catalogs);
            resolve(_this.get('items'));
          }).catch(function (error) {
            return reject(error);
          });
        }
      }).then(function () {
        _this.set('selected', A());
        var selected = _this.get('selected');
        var initialSelection = _this.get('initialSelection');
        if (initialSelection) {
          selected.pushObjects(initialSelection.toArray());
        }
        _this.addObserver('initialSelection.[]', function () {
          if (initialSelection) {
            initialSelection.toArray().forEach(function (item) {
              if (!selected.includes(item)) {
                selected.pushObject(item);
              }
            });
          }
        });
      });
    },


    actions: {
      drillDown: function drillDown(item) {
        var _this2 = this;

        if (item.get('organizationId')) {
          this.get('parents').pushObject(item);
          this.get('store').query('service', { catalogId: item.get('id') }).then(function (services) {
            return _this2.set('items', services);
          });
        }
      },
      levelUp: function levelUp() {
        var parents = this.get('parents');
        parents.popObject();
        if (!isEmpty(parents)) {
          this.set('items', parents.get('lastObject.services'));
        } else {
          this.set('items', this.get('rootItems'));
        }
      },
      submit: function submit() {
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      },
      cancel: function cancel() {
        this.get('selected').clear();
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      }
    }
  });
});