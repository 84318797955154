define('scm/pods/delivery/route', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'scm/config/environment'], function (exports, _fileSaver, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend(_fileSaver.default, {
    session: service('session'),

    model: function model() {
      return this.store.findAll('delivery');
    },


    actions: {
      downloadDeliveryReport: function downloadDeliveryReport(delivery) {
        var params = {
          orderNumber: '22038265',
          assignmentSite: 'Třínec'
        };

        var query = Object.keys(params).map(function (key) {
          return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        var xhr = new XMLHttpRequest();
        var _config$APP = _environment.default.APP,
            apiHost = _config$APP.apiHost,
            namespace = _config$APP.namespace;

        var url = apiHost + '/' + namespace + '/deliveries/' + delivery.id + '/report';
        xhr.open('GET', url + '?' + query);

        var token = this.get('session.session.content.authenticated.access_token');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);

        xhr.responseType = 'arraybuffer';

        var self = this;
        xhr.onload = function () {
          if (this.status === 200) {
            var blob = new Blob([xhr.response]);
            self.saveFileAs(delivery.get('number') + '-' + delivery.get('assignment.name') + '.xlsx', blob, 'application/vnd.ms-excel');
          }
        };

        xhr.send();
      },
      archiveDelivery: function archiveDelivery(delivery) {
        var xhr = new XMLHttpRequest();
        var _config$APP2 = _environment.default.APP,
            apiHost = _config$APP2.apiHost,
            namespace = _config$APP2.namespace;

        var url = apiHost + '/' + namespace + '/deliveries/' + delivery.id;
        xhr.open('PATCH', '' + url);

        var token = this.get('session.session.content.authenticated.access_token');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);

        xhr.onload = function () {
          if (this.status === 200) {
            delivery.unloadRecord();
          }
        };

        xhr.send();
      },
      deleteDelivery: function deleteDelivery(delivery) {
        delivery.destroyRecord();
      }
    }
  });
});