define('scm/pods/service/service-form/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    store: service(),
    keyboardActivated: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('store').findAll('task').then(function (tasks) {
        _this.set('tasks', tasks);
      });
    },


    actions: {
      submit: function submit() {
        this.get('onSubmit').apply(undefined, arguments);
      },
      cancel: function cancel() {
        this.get('onCancel').apply(undefined, arguments);
      },
      formSubmit: function formSubmit() {
        this.$().find('form').submit();
      }
    },
    onCtrlEnterPressed: on((0, _emberKeyboard.keyDown)('ctrl+Enter'), function () {
      this.send('formSubmit');
    }),
    onEscPressed: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.send('cancel');
    })
  });
});