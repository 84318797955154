define('scm/pods/assignment/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('assignment').then(function (assignments) {
        return assignments.toArray().sort(function (a, b) {
          return parseInt(b.get('id')) - parseInt(a.get('id'));
        }).filter(function (assignment) {
          return !assignment.get('isArchived');
        });
      });
    },

    actions: {
      archiveAssignment: function archiveAssignment(assignment) {
        var _this = this;

        console.log('archiving', assignment);
        assignment.set('isArchived', true);
        assignment.save().then(function () {
          _this.get('toaster').show('Změny úkolu byly uloženy.');
          _this.refresh();
        }).catch(function (reason) {
          assignment.set('isArchived', false);
          console.error(reason);
        });
      },
      deleteAssignment: function deleteAssignment(assignment) {
        var _this2 = this;

        return assignment.destroyRecord().then(function () {
          _this2.get('toaster').show('\xDAkol \'' + assignment.get('name') + '\' byl smaz\xE1n se v\u0161emi pod\u0159\xEDzen\xFDmi objekty.');
          _this2.refresh();
        }).catch(function (reason) {
          console.error(reason);
        });
      }
    }
  });
});