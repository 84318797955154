define('scm/pods/catalog/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('catalog');
    },

    actions: {
      deleteCatalog: function deleteCatalog(catalog) {
        var self = this;
        return catalog.destroyRecord().then(function () {
          self.transitionTo('catalog');
        }).catch(function (reason) {
          console.log(reason);
        });
      }
    }
  });
});