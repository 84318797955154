define('scm/pods/inventory-item/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      navigateUp: function navigateUp() {
        var parentId = this.get('model.parent.id');
        if (parentId) {
          this.transitionToRoute('inventoryItem', this.get('model.parent'));
        } else {
          this.transitionToRoute('inventory');
        }
      }
    }
  });
});