define('scm/pods/assignment/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('assignment', params.assignment_id);
    },


    actions: {
      updateAssignment: function updateAssignment(assignment) {
        var _this = this;

        assignment.save().then(function () {
          _this.get('toaster').show('Změny úkolu byly uloženy.');
          _this.transitionTo('assignment');
        }).catch(function (reason) {
          var message = 'Změny úkolu se nepodařilo uložit.';
          if (reason.errors && reason.errors.length) {
            reason.errors.forEach(function (error) {
              message = message + '\nChyba: "' + error.detail + '".';
            });
          }
          _this.get('toaster').show(message, { duration: 10000 });
        });
      },
      cancelAssignmentEdit: function cancelAssignmentEdit(assignment) {
        assignment.rollbackAttributes();
        this.transitionTo('assignment');
      }
    }
  });
});