define('scm/router', ['exports', 'scm/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('logout');
    this.route('task', { path: 'tasks' });
    this.route('task.new', { path: 'tasks/new' });
    this.route('task.edit', { path: 'tasks/:task_id/edit' });
    this.route('catalog', { path: 'catalogs' });
    this.route('catalog.show', { path: 'catalogs/:catalog_id/services' });
    this.route('catalog.edit', { path: 'catalogs/:catalog_id/edit' });
    this.route('catalog.new', { path: 'catalogs/new' });
    this.route('service.new', { path: 'catalogs/:catalog_id/services/new' });
    this.route('service.edit', { path: 'catalogs/:catalog_id/services/:service_id/edit' });
    this.route('inventory', { path: 'inventory' });
    this.route('inventoryItem', { path: 'inventory/:item_id' });
    this.route('assignment', { path: 'assignments' });
    this.route('assignment.new', { path: 'assignments/new' });
    this.route('assignment.show', { path: 'assignments/:assignment_id' });
    this.route('assignment.edit', { path: 'assignments/:assignment_id/edit' });
    this.route('work.new', { path: 'assignments/:assignment_id/works/new' });
    this.route('delivery', { path: 'deliveries' });
    this.route('delivery.new', { path: 'assignment/:assignment_id/deliveries/new' });
    this.route('statistic', { path: 'statistic' });
    this.route('budget', { path: 'budget' });
    this.route('budget.settings', { path: 'budget/settings' });
    this.route('sandbox');
  });

  exports.default = Router;
});