define('scm/pods/work/work-favorites/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    isExpanded: undefined,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isExpanded', false);
    },


    actions: {
      selectFavorite: function selectFavorite(favorite) {
        this.get('onSelect')(favorite);
        this.toggleProperty('isExpanded');
      },
      removeFavorite: function removeFavorite(favorite) {
        this.get('onDelete')(favorite);
        this.toggleProperty('isExpanded');
      },
      createFavorite: function createFavorite(name) {
        this.get('onCreate')(name, this);
        this.set('favoriteName', '');
        this.toggleProperty('isExpanded');
      },
      updateFavorite: function updateFavorite(favoriteItem) {
        this.get('onUpdate')(favoriteItem);
      }
    }
  });
});