define('scm/pods/service/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('service', params.service_id);
    },

    actions: {
      updateService: function updateService(service) {
        var _this = this;

        service.save().then(function () {
          _this.transitionTo('catalog.show', service.get('catalog.id'));
        }).catch(function (reason) {
          console.error(reason);
        });
      },
      cancelServiceEdit: function cancelServiceEdit(service) {
        service.rollbackAttributes();
        this.transitionTo('catalog.show', service.get('catalog.id'));
      }
    }
  });
});