define('scm/pods/assignment/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('assignment', params.assignment_id, { reload: true }).then(function (assignment) {
        return assignment.get('works').then(function () {
          return assignment;
        });
      });
    },


    actions: {
      removeWork: function removeWork(work) {
        var _this = this;

        work.destroyRecord().then(function () {
          return _this.get('toaster').show('Z\xE1znam pr\xE1ce byl odstran\u011Bn.');
        });
      },
      updateWork: function updateWork(work) {
        var _this2 = this;

        work.save().then(function () {
          return _this2.get('toaster').show('Z\xE1znam pr\xE1ce byl ulo\u017Een.');
        });
      }
    }
  });
});