define('scm/pods/current-user/service', ['exports', 'scm/config/environment', 'scm/pods/current-user/user'], function (exports, _environment, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    session: service(),
    store: service(),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        var token = this.get('session.data.authenticated.access_token'),
            headers = { authorization: 'Bearer ' + token },
            _config$APP = _environment.default.APP,
            apiHost = _config$APP.apiHost,
            namespace = _config$APP.namespace;

        return $.ajax(apiHost + '/' + namespace + '/auth/user', { headers: headers }).then(function (payload) {
          var data = payload.user;
          var organization = {
            id: data.organizationId,
            organizationId: data.organizationId,
            code: data.organizationCode,
            name: data.organizationName
          };
          var user = _user.default.create({
            id: data.userId,
            login: data.userName,
            name: data.personName,
            email: data.email,
            roles: data.roles,
            isVendor: data.roles.includes('VENDOR'),
            organization: organization
          });
          _this.set('currentUser', data);
          _this.set('isVendor', data.roles.includes('VENDOR'));
          _this.set('user', user);
          return _this.get('store').findRecord('organization', data.organizationId);
        }).then(function (organization) {
          _this.set('user.organization', organization);
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});