define('scm/helpers/fmt-join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fmtJoin = fmtJoin;
  var helper = Ember.Helper.helper;
  function fmtJoin(params, _ref) {
    var _ref$separator = _ref.separator,
        separator = _ref$separator === undefined ? ',' : _ref$separator;

    return params.filter(function (val) {
      return val;
    }).join(separator);
  }

  exports.default = helper(fmtJoin);
});