define('scm/pods/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'scm/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.apiHost,
    namespace: _environment.default.APP.namespace,
    authorizer: 'authorizer:oauth2',

    pathForType: function pathForType(type) {
      return type === 'inventory-item' ? 'inventory' : this._super(type);
    }
  });
});