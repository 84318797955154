define('scm/pods/catalog/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    currentUser: service(),

    model: function model() {
      return this.store.createRecord('catalog', {
        organizationId: this.get('currentUser.currentUser.organizationId')
      });
    },

    actions: {
      createCatalog: function createCatalog(catalog) {
        var self = this;
        catalog.save().then(function () {
          self.transitionTo('catalog');
        }).catch(function (reason) {
          console.error(reason);
        });
      },
      cancelCatalogCreation: function cancelCatalogCreation(catalog) {
        catalog.destroyRecord();
        this.transitionTo('catalog');
      }
    }
  });
});