enifed('ember-glimmer/helpers/-input-type', ['exports', 'ember-glimmer/utils/references'], function (exports, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return new _references.InternalHelperReference(inputTypeHelper, args.capture());
    };

    function inputTypeHelper(_ref) {
        var positional = _ref.positional;

        var type = positional.at(0).value();
        if (type === 'checkbox') {
            return '-checkbox';
        }
        return '-text-field';
    }
});