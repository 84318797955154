define('scm/pods/assignment/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.createRecord('assignment');
    },


    actions: {
      createAssignment: function createAssignment(assignment) {
        var _this = this;

        assignment.save().then(function () {
          _this.get('toaster').show('Nov\xFD \xFAkol \'' + assignment.get('name') + '\' byl ulo\u017Een.');
          _this.transitionTo('assignment');
        }).catch(function (res) {
          res.errors.forEach(function (error) {
            _this.get('toaster').show('Nov\xFD \xFAkol se nepoda\u0159ilo ulo\u017Eit. (' + error.title + ': ' + error.detail + ')');
            console.error(error);
          });
        });
      },
      cancelAssignmentCreation: function cancelAssignmentCreation(assignment) {
        assignment.destroyRecord();
        this.transitionTo('assignment');
      }
    }
  });
});