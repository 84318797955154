define('scm/pods/work/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var EmberPromise = Ember.RSVP.Promise;
  var all = Ember.RSVP.all;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('assignment', params.assignment_id, { reload: true }).then(function (assignment) {
        return new EmberPromise(function (resolve, reject) {
          _this.store.findAll('service') // pre-fetch all services
          .then(function () {
            return resolve(assignment);
          }, reject);
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('works', A());
    },


    actions: {
      createWork: function createWork(works, assignmentId) {
        var _this2 = this;

        var saveAll = [];
        works.forEach(function (work) {
          return saveAll.push(work.save());
        });
        all(saveAll).then(function () {
          _this2.get('toaster').show('Polo\u017Eky byly p\u0159id\xE1ny (po\u010Det: ' + saveAll.length + ').');
          _this2.transitionTo('assignment.show', assignmentId);
        }).catch(function (res) {
          res.errors.forEach(function (error) {
            _this2.get('toaster').show('Polo\u017Eky se nepoda\u0159ilo p\u0159idat. (' + error.title + ': ' + error.detail + ')');
            console.error(error);
          });
        });
      },
      cancelWorkCreation: function cancelWorkCreation(works, assignmentId) {
        works.forEach(function (work) {
          return work.destroyRecord();
        });
        this.transitionTo('assignment.show', assignmentId);
      }
    }
  });
});