define('scm/pods/application/select-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    selectedOptions: null,
    option: null,
    isOptionSelected: computed('option', 'selectedOptions.[]', function () {
      var option = this.get('option');
      var selectedOptions = this.get('selectedOptions');
      if (option && selectedOptions) {
        return selectedOptions.indexOf(option) >= 0;
      } else {
        return false;
      }
    }),
    actions: {
      toggleOptionSelection: function toggleOptionSelection() {
        var selectedOptions = this.get('selectedOptions');
        if (this.get('isOptionSelected')) {
          selectedOptions.removeObject(this.get('option'));
        } else {
          selectedOptions.pushObject(this.get('option'));
        }
      }
    }
  });
});