define('scm/pods/delivery/delivery-form/component', ['exports', 'ember-keyboard', 'scm/config/environment', 'moment', 'scm/helpers/amount'], function (exports, _emberKeyboard, _environment, _moment, _amount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    keyboardActivated: true,
    csCalendar: _environment.default.csCalendar,
    dateFormat: _environment.default.csDateFormat,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('worksFrom', this._dateToStringOf('delivery.periodFrom'));
      this.set('worksTo', this._dateToStringOf('delivery.periodTo'));
    },
    _dateToStringOf: function _dateToStringOf(path) {
      return _moment.default.utc(this.get(path)).format(this.get('dateFormat'));
    },
    _momentOf: function _momentOf(path) {
      return _moment.default.utc(this.get(path), this.get('dateFormat'));
    },


    worksFrom: null,
    worksTo: null,
    works: computed('worksFrom', 'worksTo', function () {
      var from = this._momentOf('worksFrom');
      var to = this._momentOf('worksTo');
      return this.get('delivery.assignment.works').filter(function (work) {
        var completedOn = (0, _moment.default)(work.get('completedOn'));
        return completedOn.isBetween(from, to, 'day', '[]');
      });
    }),

    totalPrice: computed('works.@each.price', function () {
      return (0, _amount.round)(this.get('works').map(function (work) {
        return work.get('price');
      }).reduce(function (sum, price) {
        return sum += price;
      }, 0));
    }),

    actions: {
      submit: function submit() {
        this.set('delivery.periodFrom', this._momentOf('worksFrom').toDate());
        this.set('delivery.periodTo', this._momentOf('worksTo').toDate());
        this.set('delivery.totalPrice', this.get('totalPrice'));
        this.get('onSubmit').apply(undefined, arguments);
      },
      cancel: function cancel() {
        this.get('onCancel').apply(undefined, arguments);
      },
      formSubmit: function formSubmit() {
        this.$().find('form').submit();
      }
    },
    onCtrlEnterPressed: on((0, _emberKeyboard.keyDown)('ctrl+Enter'), function () {
      this.send('formSubmit');
    }),
    onEscPressed: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.send('cancel');
    })
  });
});