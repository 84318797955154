define('scm/pods/budget/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController() {
      this.controllerFor('budget').get('calculateBalance').perform();
    }
  });
});