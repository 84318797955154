define('scm/pods/budget/settings/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({

    preferences: service(),

    model: function model() {
      return this.get('preferences').find('budget');
    },


    actions: {
      saveBudget: function saveBudget(budget) {
        this.get('preferences').persist('budget', budget);
        this.transitionTo('budget');
      }
    }
  });
});