define('scm/pods/service/select-services/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var A = Ember.A;
  var computed = Ember.computed;
  var sort = Ember.computed.sort;
  exports.default = Component.extend({
    tagName: 'span',
    catalogs: null,
    services: null,
    servicesSorting: Object.freeze(['name']),
    servicesSorted: sort('services', 'servicesSorting'),
    initialSelection: null,
    selected: null,
    selectedCount: computed('selected.[]', function () {
      return this.get('selected.length');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('services', A());
      this.set('selected', A());
      var catalogs = this.get('catalogs');
      if (catalogs) {
        var services = this.get('services');
        catalogs.forEach(function (catalog) {
          var catalogServices = A();
          catalog.get('services').forEach(function (service) {
            return catalogServices.push(service);
          });
          services.pushObjects(catalogServices);
        });
      }
      var selected = this.get('selected');
      var initialSelection = this.get('initialSelection');
      if (initialSelection) {
        selected.pushObjects(initialSelection.toArray());
      }
      this.addObserver('initialSelection.[]', function () {
        if (initialSelection) {
          initialSelection.toArray().forEach(function (item) {
            if (!selected.includes(item)) {
              selected.pushObject(item);
            }
          });
        }
      });
    },

    actions: {
      submit: function submit() {
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      },
      cancel: function cancel() {
        this.get('selected').clear();
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      },
      sortBy: function sortBy(prop) {
        this.set('servicesSorting', [prop]);
      }
    }
  });
});