enifed('ember-glimmer/components/text_area', ['exports', 'ember-views', 'ember-glimmer/component', 'ember-glimmer/templates/empty'], function (exports, _emberViews, _component, _empty) {
  'use strict';

  exports.default = _component.default.extend(_emberViews.TextSupport, {
    classNames: ['ember-text-area'],
    layout: _empty.default,
    tagName: 'textarea',
    attributeBindings: ['rows', 'cols', 'name', 'selectionEnd', 'selectionStart', 'wrap', 'lang', 'dir', 'value'],
    rows: null,
    cols: null
  });
});