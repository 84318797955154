define('scm/models/task', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    unit: _emberData.default.attr('string'),
    genre: _emberData.default.attr('string'),
    estate: _emberData.default.attr('string'),

    searchTerm: computed('code', 'description', function () {
      return this.get('code') + ' ' + this.get('description');
    }),
    estateOptions: computed('estate', function () {
      return this.get('estate').split(/\|/);
    }),
    hasEstateOptions: computed('estateOptions', function () {
      return this.get('estateOptions').length > 1;
    })
  });
});