define('scm/pods/catalog/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('catalog', params.catalog_id);
    },

    actions: {
      updateCatalog: function updateCatalog(catalog) {
        var self = this;
        catalog.save().then(function () {
          self.transitionTo('catalog');
        }).catch(function (reason) {
          console.error(reason);
        });
      },
      cancelCatalogEdit: function cancelCatalogEdit(catalog) {
        catalog.rollbackAttributes();
        this.transitionTo('catalog');
      }
    }
  });
});