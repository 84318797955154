enifed('ember-glimmer/helpers/-class', ['exports', 'ember-runtime', 'ember-glimmer/utils/references'], function (exports, _emberRuntime, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return new _references.InternalHelperReference(classHelper, args.capture());
    };

    function classHelper(_ref) {
        var positional = _ref.positional;

        var path = positional.at(0);
        var args = positional.length;
        var value = path.value();
        if (value === true) {
            if (args > 1) {
                return _emberRuntime.String.dasherize(positional.at(1).value());
            }
            return null;
        }
        if (value === false) {
            if (args > 2) {
                return _emberRuntime.String.dasherize(positional.at(2).value());
            }
            return null;
        }
        return value;
    }
});