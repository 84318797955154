define('scm/pods/preferences/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    store: service(),

    init: function init() {
      this._super.apply(this, arguments);
    },
    find: function find(key) {
      return this.get('store').queryRecord('option', { key: key }).then(function (option) {
        return option ? JSON.parse(option.get('value')) : null;
      });
    },
    persist: function persist(key, value) {
      var valueString = JSON.stringify(value, null, 2);
      var store = this.get('store');
      return store.queryRecord('option', { key: key }).then(function (option) {
        var record = option || store.createRecord('option', { key: key });
        record.set('value', valueString);
        return Object.keys(record.changedAttributes()).length ? record.save() : RSVP.resolve(record);
      });
    },
    remove: function remove(key) {
      return this.get('store').queryRecord('option', { key: key }).then(function (option) {
        if (option) {
          return option.destroyRecord();
        }
        return RSVP.resolve();
      });
    }
  });
});