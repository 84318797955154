define('scm/models/assignment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    catalogs: _emberData.default.hasMany('catalog'),
    inventory: _emberData.default.hasMany('inventory-item'),
    works: _emberData.default.hasMany('work'),
    deliveries: _emberData.default.hasMany('delivery'),
    organizations: _emberData.default.hasMany('organization'),
    isArchived: _emberData.default.attr('boolean', { defaultValue: false }),
    hasWorks: _emberData.default.attr('boolean', { defaultValue: false }),

    totalPrice: computed('works.@each.price', function () {
      return this.get('works').map(function (work) {
        return work.get('price');
      }).reduce(function (sum, price) {
        return sum += price;
      }, 0);
    })
  });
});