define('scm/pods/service/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('catalog', params.catalog_id).then(function (catalog) {
        return _this.store.createRecord('service', {
          catalog: catalog
        });
      });
    },

    actions: {
      createService: function createService(service) {
        var _this2 = this;

        service.save().then(function () {
          _this2.transitionTo('catalog.show', service.get('catalog.id'));
        }).catch(function (reason) {
          console.error(reason);
        });
      },
      cancelServiceCreation: function cancelServiceCreation(service) {
        var catalogId = service.get('catalog.id');
        service.destroyRecord();
        this.transitionTo('catalog.show', catalogId);
      }
    }
  });
});