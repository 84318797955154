define('scm/pods/login/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    session: service('session'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        this.get('session').authenticate('authenticator:oauth2', identification, password).catch(function (reason) {
          _this.set('errorMessage', reason.error || reason);
        });
      }
    }
  });
});