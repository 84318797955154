define('scm/pods/work/work-favorite-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',

    isEditing: undefined,
    favorite: undefined,
    index: undefined,
    position: undefined,
    uneditedValues: undefined,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('position', this.get('index') + 1);
    },


    actions: {
      select: function select() {
        this.get('onSelect')(this.get('favorite'));
      },
      edit: function edit() {
        this.set('isEditing', true);
        this.set('uneditedValues', {
          name: this.get('favorite.name'),
          position: this.get('position')
        });
      },
      cancel: function cancel() {
        this.set('isEditing', false);
        this.set('favorite.name', this.get('uneditedValues').name);
        this.set('position', this.get('uneditedValues').position);
      },
      update: function update() {
        this.set('isEditing', false);
        var position = this.get('position');
        if (isNaN(position)) {
          position = this.get('index') + 1;
        }
        position = Math.max(position, 1);
        this.set('position', position);
        if (this.get('favorite.name') !== this.get('uneditedValues').name || this.get('position') !== this.get('uneditedValues').position) {
          this.get('onUpdate')(this);
        }
      },
      remove: function remove() {
        this.get('onRemove')(this.get('favorite'));
      }
    }
  });
});