define('scm/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'scm/config/environment', 'npm:sha.js'], function (exports, _oauth2PasswordGrant, _environment, _npmSha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = _oauth2PasswordGrant.default.extend({
    makeRequest: function makeRequest(url, data) {
      var sha256 = (0, _npmSha.default)('sha256');
      var authReq = {
        userName: data.username,
        passwordHash: sha256.update(data.username + data.password, 'utf8').digest('hex')
      };

      return $.ajax({
        url: _environment.default.APP.apiHost + '/api/auth/token',
        type: 'POST',
        data: JSON.stringify(authReq),
        dataType: 'json',
        contentType: 'application/json'
      });
    }
  });
});