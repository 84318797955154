define('scm/pods/catalog/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return hash({
        catalog: this.store.findRecord('catalog', params.catalog_id, { reload: true }),
        services: this.store.query('service', { catalogId: params.catalog_id })
      });
    },

    actions: {
      deleteService: function deleteService(service) {
        var _this = this;

        var catalogId = service.get('catalog.id');
        return service.destroyRecord().then(function () {
          _this.transitionTo('catalog.show', catalogId);
        }).catch(function (reason) {
          console.log(reason);
        });
      }
    }
  });
});