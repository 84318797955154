define('scm/helpers/fmt-first', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fmtFirst = fmtFirst;
  var helper = Ember.Helper.helper;
  function fmtFirst(params) {
    return params.filter(function (val) {
      return val;
    })[0];
  }

  exports.default = helper(fmtFirst);
});