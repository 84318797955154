define('scm/pods/assignment/assignment-work-form/component', ['exports', 'ember-keyboard', 'scm/config/environment', 'moment'], function (exports, _emberKeyboard, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var A = Ember.A;
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  var EmberObject = Ember.Object;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    currentUser: service(),
    store: service(),
    preferences: service(),
    favorites: undefined,
    keyboardActivated: true,
    assignment: null,
    assignmentInventory: alias('assignment.inventory'),
    selectedInventory: null,
    assignmentCatalogs: alias('assignment.catalogs'),
    selectedServices: null,
    csCalendar: _environment.default.csCalendar,
    dateFormat: _environment.default.csDateFormat,
    completedOn: null,
    works: null,
    workCount: computed('works.[]', function () {
      return this.get('works.length');
    }),
    workTotal: computed('works.@each.price', function () {
      return this.get('works').reduce(function (sum, work) {
        return sum + work.get('price');
      }, 0);
    }),
    hasWorks: computed('workCount', function () {
      return this.get('workCount') > 0;
    }),
    isEstatePopulated: computed('works.@each.estate', function () {
      return this.get('works').every(function (work) {
        return !!work.get('estate');
      });
    }),

    _fetchWorkFavorites: function _fetchWorkFavorites() {
      var _this = this;

      this.get('preferences').find('workFavorites').then(function (favorites) {
        var values = favorites ? A(favorites.map(function (favorite) {
          return EmberObject.create(favorite);
        })) : A();
        _this.set('favorites', values);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedInventory', A());
      this.set('selectedServices', A());
      this.set('completedOn', _moment.default.utc().format(this.get('dateFormat')));
      this._fetchWorkFavorites();
    },
    checkService: function checkService(results) {
      var assignmentCatalogIds = this.get('assignment.catalogs').mapBy('id');
      var service = results.service;

      var catalogId = service.get('catalog.id');
      if (!assignmentCatalogIds.includes(catalogId)) {
        throw new Error('service catalog ' + catalogId + ' is not part of the assignment');
      }
      return RSVP.resolve(results);
    },
    areRelated: function areRelated(parent, child) {
      var _this2 = this;

      if (parent.get('id') === child.get('id')) {
        return RSVP.resolve(true);
      }
      return child.get('parent').then(function (ancestor) {
        if (ancestor.get('id')) {
          return _this2.areRelated(parent, ancestor);
        }
        return RSVP.resolve(false);
      }).catch(function () {
        return RSVP.resolve(false);
      });
    },
    checkInventory: function checkInventory(results) {
      var _this3 = this;

      var item = results.inventory;
      var parents = this.get('assignment.inventory');
      var relations = [];
      parents.forEach(function (parent) {
        relations.push(_this3.areRelated(parent, item));
      });
      return RSVP.all(relations).then(function (related) {
        if (!related.any(function (state) {
          return state;
        })) {
          throw new Error('inventory item ' + item.get('id') + ' is not related to the assignment');
        }
        return results;
      });
    },


    actions: {
      removeWork: function removeWork(work) {
        work.destroyRecord();
        this.get('works').removeObject(work);
      },
      selectInventory: function selectInventory(inventory) {
        this.get('selectedInventory').setObjects(inventory);
        this.refreshWorks();
      },
      selectServices: function selectServices(services) {
        this.get('selectedServices').setObjects(services);
        this.refreshWorks();
      },
      selectFavorite: function selectFavorite(favorite) {
        var _this4 = this;

        var store = this.get('store');
        A(favorite.works).forEach(function (template) {
          RSVP.hash({
            service: store.findRecord('service', template.serviceId),
            inventory: store.findRecord('inventory-item', template.inventoryId)
          }).then(function (results) {
            return _this4.checkService(results);
          }).then(function (results) {
            return _this4.checkInventory(results);
          }).then(function (results) {
            var service = results.service,
                inventory = results.inventory;


            _this4.get('selectedServices').addObject(service);
            _this4.get('selectedInventory').addObject(inventory);

            var estate = service.get('task.hasEstateOptions') ? template.estate : service.get('task.estate');
            if (!service.get('task.estateOptions').includes(estate)) {
              estate = '';
            }
            var work = store.createRecord('work', {
              completedOn: _moment.default.utc(_this4.get('completedOn'), _this4.get('dateFormat')).toDate(),
              assignment: _this4.get('assignment'),
              service: service,
              inventory: inventory,
              rate: service.get('unitPrice'),
              amount: template.amount,
              details: template.details,
              genre: service.get('task.genre'),
              estate: estate
            });
            _this4.get('works').pushObject(work);
          }).catch(function (error) {
            return console.log(error);
          });
        });
      },
      saveFavorite: function saveFavorite(name) {
        var works = this.get('works').map(function (work) {
          return {
            serviceId: work.get('service.id'),
            inventoryId: work.get('inventory.id'),
            amount: work.get('amount'),
            details: work.get('details'),
            estate: work.get('estate')
          };
        });
        var favorites = this.get('favorites');
        favorites.pushObject({ name: name, works: works });
        this.get('preferences').persist('workFavorites', favorites.toArray());
      },
      updateFavorite: function updateFavorite(favoriteItem) {
        var favorites = this.get('favorites').toArray();
        var index = Math.min(favoriteItem.get('position'), favorites.length) - 1;
        var originalIndex = favoriteItem.get('index');
        var indexChanged = originalIndex !== index;
        var nameChanged = favoriteItem.get('uneditedValues').name !== favoriteItem.get('favorite.name');
        if (indexChanged) {
          favorites.splice(index, 0, favorites.splice(originalIndex, 1)[0]);
        }
        if (indexChanged || nameChanged) {
          this.get('preferences').persist('workFavorites', favorites);
        }
        this._fetchWorkFavorites();
      },
      deleteFavorite: function deleteFavorite(favorite) {
        var favorites = this.get('favorites');
        favorites.removeObject(favorite);
        this.get('preferences').persist('workFavorites', favorites.toArray());
      },
      submit: function submit() {
        this.get('onSubmit')(this.get('works'), this.get('assignment.id'));
      },
      cancel: function cancel() {
        this.get('onCancel')(this.get('works'), this.get('assignment.id'));
      },
      formSubmit: function formSubmit() {
        this.$().find('form').submit();
      }
    },

    refreshWorks: function refreshWorks() {
      var _this5 = this;

      var store = this.get('store');
      var assignment = this.get('assignment');
      var inventory = this.get('selectedInventory');
      var services = this.get('selectedServices');
      if (inventory && services) {
        var works = this.get('works');
        var current = {};
        works.forEach(function (work) {
          return current[work.get('service.id') + work.get('inventory.id')] = work;
        });
        works.clear();
        inventory.forEach(function (item) {
          services.forEach(function (service) {
            var workId = service.get('id') + item.get('id');
            var work = void 0;
            if (current.hasOwnProperty(workId)) {
              work = current[workId];
              delete current[workId];
            } else {
              var amount = void 0;
              switch (service.get('task.unit')) {
                case 'bm':
                  amount = item.get('length') || 1;
                  break;
                case 'm2':
                  amount = item.get('size') || 1;
                  break;
                default:
                  amount = 1;
              }
              work = store.createRecord('work', {
                completedOn: _moment.default.utc(_this5.get('completedOn'), _this5.get('dateFormat')).toDate(),
                assignment: assignment,
                supplyOrganization: _this5.get('currentUser.user.organization'),
                service: service,
                inventory: item,
                rate: service.get('unitPrice'),
                amount: amount,
                genre: service.get('task.genre'),
                estate: !service.get('task.hasEstateOptions') ? service.get('task.estateOptions')[0] : ''
              });
            }
            works.pushObject(work);
          });
        });
        Object.keys(current).forEach(function (workId) {
          var work = current[workId];
          work.destroyRecord();
          delete current[workId];
        });
      }
    },


    onCtrlEnterPressed: on((0, _emberKeyboard.keyDown)('ctrl+Enter'), function () {
      this.send('formSubmit');
    }),
    onEscPressed: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.send('cancel');
    })
  });
});