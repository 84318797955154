define('scm/pods/assignment/assignment-form/component', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var on = Ember.on;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var A = Ember.A;
  exports.default = Component.extend(_emberKeyboard.EKMixin, {
    currentUser: service(),
    selectedInventory: A(),
    selectedCatalogs: A(),
    selectedOrganizations: A(),
    keyboardActivated: true,
    userOrganizationId: alias('currentUser.user.organization.organizationId'),
    submitDisabled: computed('selectedInventory.[]', 'selectedCatalogs.[]', function () {
      return this.get('selectedInventory.length') === 0 || this.get('selectedCatalogs.length') === 0;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var catalogs = this.get('assignment.catalogs').toArray();
      if (catalogs) {
        this.get('selectedCatalogs').pushObjects(catalogs);
      }
      var inventory = this.get('assignment.inventory').toArray();
      if (inventory) {
        this.get('selectedInventory').pushObjects(inventory);
      }
      var organizations = this.get('assignment.organizations').toArray();
      if (organizations) {
        this.get('selectedOrganizations').pushObjects(organizations);
      }
    },


    actions: {
      submit: function submit() {
        this.get('onSubmit').apply(undefined, arguments);
      },
      cancel: function cancel() {
        this.get('onCancel').apply(undefined, arguments);
      },
      formSubmit: function formSubmit() {
        this.$().find('form').submit();
      },
      selectInventory: function selectInventory(inventory) {
        var selectedInventory = this.get('selectedInventory');
        selectedInventory.clear();
        selectedInventory.pushObjects(inventory);
        this.get('assignment').set('inventory', this.get('selectedInventory'));
      },
      selectCatalogs: function selectCatalogs(catalogs) {
        var selectedCatalogs = this.get('selectedCatalogs');
        selectedCatalogs.clear();
        selectedCatalogs.pushObjects(catalogs);
        this.get('assignment').set('catalogs', this.get('selectedCatalogs'));
      },
      selectOrganizations: function selectOrganizations(organizations) {
        var selectedOrganizations = this.get('selectedOrganizations');
        selectedOrganizations.clear();
        selectedOrganizations.pushObjects(organizations);
        this.get('assignment').set('organizations', this.get('selectedOrganizations'));
      }
    },
    onCtrlEnterPressed: on((0, _emberKeyboard.keyDown)('ctrl+Enter'), function () {
      this.send('formSubmit');
    }),
    onEscPressed: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.send('cancel');
    })
  });
});