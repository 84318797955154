define('scm/initializers/session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'currentUser', 'service:currentUser');
    application.inject('controller', 'session', 'service:session');
  }

  exports.default = {
    initialize: initialize
  };
});