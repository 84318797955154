define('scm/pods/catalog/select-catalogs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var A = Ember.A;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service('store'),

    tagName: 'span',
    items: null,
    initialSelection: null,
    selected: null,
    selectedCount: computed('selected.[]', function () {
      return this.get('selected.length');
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('store').findAll('catalog').then(function (catalogs) {
        _this.set('items', catalogs);
        _this.set('selected', A());
        var initialSelection = _this.get('initialSelection');
        if (initialSelection) {
          _this.get('selected').pushObjects(initialSelection.toArray());
        }
      });
    },

    actions: {
      submit: function submit() {
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      },
      cancel: function cancel() {
        this.get('selected').clear();
        this.get('onSubmit')(this.get('selected'));
        this.set('showModal', false);
      }
    }
  });
});