define('scm/models/work', ['exports', 'ember-data', 'scm/helpers/amount'], function (exports, _emberData, _amount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _emberData.default.Model.extend({
    assignment: _emberData.default.belongsTo('assignment'),
    service: _emberData.default.belongsTo('service'),
    inventory: _emberData.default.belongsTo('inventory-item'),
    supplyOrganization: _emberData.default.belongsTo('organization'),
    completedOn: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    rate: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number'),
    details: _emberData.default.attr('string'),
    genre: _emberData.default.attr('string'),
    estate: _emberData.default.attr('string'),

    supplyOrganizationCode: alias('supplyOrganization.code'),
    inventoryNumber: alias('inventory.number'),
    inventoryName: alias('inventory.name'),
    inventoryUnit: alias('inventory.unit'),
    serviceName: alias('service.task.description'),
    serviceUnit: alias('service.task.unit'),
    serviceGenre: alias('service.task.genre'),
    serviceEstate: alias('service.task.estate'),
    serviceEstateOptions: alias('service.task.estateOptions'),
    hasServiceEstateOptions: alias('service.task.hasEstateOptions'),
    price: computed('{rate,amount}', function () {
      return (0, _amount.round)(this.get('rate') * this.get('amount'));
    })
  });
});