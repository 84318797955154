define('scm/pods/work/work-item/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    completedDate: null,
    isShowingDetail: null,
    isZeroRate: null,
    onSave: function onSave() {},
    onRemove: function onRemove() {},

    disableRateEdit: computed('isShowingDetail', 'isZeroRate', function () {
      return !(this.get('isShowingDetail') && this.get('isZeroRate'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isShowingDetail', false);
      this.set('disableEdit', this.getWithDefault('disableEdit', false));
      this.set('disableDetail', this.getWithDefault('disableDetail', false));
      this.set('canDelete', this.getWithDefault('canDelete', false));
      this.set('completedDate', _moment.default.utc(this.get('work.completedOn')).format('YYYY-MM-DD'));
      this.set('isZeroRate', this.get('work.rate') === 0);
    },


    actions: {
      toggleDetail: function toggleDetail() {
        if (this.get('disableDetail')) {
          return;
        }
        this.toggleProperty('isShowingDetail');
        if (!this.get('isShowingDetail')) {
          this.closingDetail();
        }
      },
      remove: function remove() {
        this.get('onRemove')(this.get('work'));
      }
    },
    closingDetail: function closingDetail() {
      if (!this.get('disableEdit')) {
        var work = this.get('work');
        var isDirty = work.get('hasDirtyAttributes');
        var completedDate = _moment.default.utc(this.get('completedDate'), 'YYYY-MM-DD').toDate();
        if (completedDate.getTime() !== work.get('completedOn').getTime()) {
          isDirty = true;
          work.set('completedOn', completedDate);
        }
        if (isDirty) {
          this.get('onSave')(this.get('work'));
        }
      }
    },
    click: function click() {
      this.send('toggleDetail');
    }
  });
});