define('scm/pods/budget/budget-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var A = Ember.A;
  var computed = Ember.computed;
  exports.default = Component.extend({
    defaultBudget: [{ genre: 'O', estate: 'CH', budget: 0 }, { genre: 'O', estate: 'MK', budget: 0 }, { genre: 'O', estate: 'DZ', budget: 0 }, { genre: 'O', estate: 'KN', budget: 0 }, { genre: 'U', estate: 'CH', budget: 0 }, { genre: 'U', estate: 'MK', budget: 0 }, { genre: 'U', estate: 'DZ', budget: 0 }, { genre: 'U', estate: 'KN', budget: 0 }, { genre: 'R', estate: 'PK', budget: 0 }],
    init: function init() {
      this._super.apply(this, arguments);
      var data = (this.get('budget') || []).map(function (estate) {
        estate.budget = parseInt(estate.budget, 10) || 0;
        return estate;
      });
      this.set('budget', this.get('defaultBudget').reduce(function (acc, defaultEstate) {
        var budgetEstate = data.find(function (estate) {
          return estate.genre === defaultEstate.genre && estate.estate === defaultEstate.estate;
        }) || defaultEstate;
        acc.push(budgetEstate);
        return acc;
      }, A()));
    },

    vm: computed('budget.@each.budget', function () {
      var genres = this.get('budget').reduce(function (acc, estate) {
        acc[estate.genre] = acc[estate.genre] || { genre: estate.genre, budget: 0, estates: [] };
        acc[estate.genre].budget += parseInt(estate.budget, 10) || 0;
        acc[estate.genre].estates.push(estate);
        return acc;
      }, {});
      return Object.values(genres).reduce(function (acc, genre) {
        acc.push({ header: true, genre: genre.genre, estate: undefined, budget: genre.budget });
        acc.push.apply(acc, _toConsumableArray(genre.estates.map(function (estate) {
          return {
            genre: estate.genre,
            estate: estate.estate,
            budget: estate.budget
          };
        })));
        return acc;
      }, []);
    }),
    total: computed('vm.@each.budget', function () {
      return this.get('vm').filter(function (estate) {
        return !estate.header;
      }).reduce(function (total, estate) {
        return total + (parseInt(estate.budget, 10) || 0);
      }, 0);
    }),

    actions: {
      save: function save() {
        this.get('onSave')(this.get('budget').map(function (estate) {
          return {
            genre: estate.genre,
            estate: estate.estate,
            budget: parseInt(estate.budget, 10) || 0
          };
        }));
      },
      updateBudget: function updateBudget(genre, estate, budget) {
        var index = this.get('budget').findIndex(function (item) {
          return item.genre === genre && item.estate === estate;
        });
        this.set('budget.' + index + '.budget', budget);
      }
    }
  });
});